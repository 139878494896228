<template>
    <div class="order">
        <!-- <van-loading type="spinner" v-if="loading" /> -->
        <van-skeleton title :row="4" v-if="loading" />
        <!--mescroll滚动区域的基本结构-->
        <div v-else>
            <mescroll-vue v-if="orderList.length != 0" ref="mescroll" style="height: calc(100vh - 80px);"
                :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
                <div class="orderCard" v-for="(item, index) in orderList" v-bind:key="index" @click="toDetail(item.id)">
                    <img src="../../images/order/poster.png" alt="" style="width:100px;">
                    <div class="orderInfo">
                        <div style="font-size: 16px;color:#333333;" class="oneRowEllipsis">
                            {{ item.commodityName }}</div>
                        <div class="display_flex" style="margin-top: 8px;">
                            <div style="width:60px;flex-shrink: 0;">下单时间</div> {{ item.createAt }}
                        </div>
                        <div class="display_flex" style="margin-top: 8px;">
                            <div style="width:60px">订单号</div>{{ item.orderNumber }}
                        </div>
                        <div style="font-size: 16px;color:#E02020;margin-top: 10px;">￥{{ item.totalAmount }}</div>
                    </div>
                    <div v-if="item.orderStatus == 0" class="orderStatus">待支付</div>
                    <div v-else-if="item.orderStatus == 1" class="orderStatus" style="background-color: #44D7B6;">已预约</div>
                    <div v-else-if="item.orderStatus == 2" class="orderStatus"
                        style="background-color: #E3EDFA;color: #1477FD;">检测中</div>
                    <div v-else-if="item.orderStatus == 3" class="orderStatus" style="background-color: #E6E6E6;">已完成</div>
                    <div v-else-if="item.orderStatus == 4" class="orderStatus" style="background-color: #E6E6E6;">已关闭</div>
                    <div v-else-if="item.orderStatus == 5" class="orderStatus" style="background-color: #E6E6E6;">已取消</div>
                    <div v-else class="orderStatus" style="background-color: #E6E6E6;">审核中</div>
                </div>
            </mescroll-vue>
            <div v-else class="emptyPic">
                <img src="../../images/order/empty.png" alt="" style="width:200px;display: inline;">
                <div style="font-weight: 400;color: #999999;line-height: 22px;">您还没有订单 !</div>
            </div>
            <div class="bottom">
                <button class="bottomButton" @click="appointment">立即预约</button>
            </div>
        </div>
    </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
import OrderApi from './api'
const orderApi = new OrderApi()
export default {
    components: {
        MescrollVue // 注册mescroll组件
    },
    data() {
        return {
            loading: true,
            mescroll: null, // mescroll实例对象
            mescrollDown: { //与mescrollTop一致，所以也可以不用写
                callback: this.downCallback,
                page: {
                    time: null,
                    size: 10
                },
                auto: false,  //是否在初始化完毕之后自动执行一次下拉刷新的回调
                noMoreSize: 5,  //列表只有5条，就展示
            },
            mescrollUp: { // 上拉加载的配置.
                callback: this.upCallback,
                page: {
                    time: null,
                    size: 10 //每页数据条数,默认10
                },
                auto: false,  //是否在初始化完毕之后自动执行一次上拉加载的回调
                noMoreSize: 5,//列表只有5条，就展示
            },
            orderList: [], // 列表数据,
            pageSize: 10,
        }
    },
    created() {
    },
    mounted() {
        this.getData()

        // 禁止返回
        if (window.history && window.history.pushState) {
            // 向历史记录中插入了当前页
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
    },
    computed: {
    },
    destroyed() {
        window.removeEventListener('popstate', this.goBack, false);
    },
    methods: {
        // 去预约
        appointment() {
            // 清除localstore
            this.clearStore()
            localStorage.setItem('returnLoc', '/order')
            this.$router.push("/addOrder")
        },
        // 清除store数据
        clearStore() {
            localStorage.removeItem('orderInfo')
            localStorage.removeItem('order')

            // 清空健康调查问卷
            localStorage.removeItem('healthSurvey')
            localStorage.removeItem('isHealthAchieve')
            // 清空用户协议
            localStorage.removeItem('agreement')
            localStorage.removeItem('userRights')
            localStorage.removeItem('isReadonly')
        },
        // 去订单详情
        toDetail(id) {
            // 清除localstore
            this.clearStore()
            this.$router.push({ path: '/orderDetail', query: { id: id } })
        },
        // 刷新订单信息
        async getData() {
            let params = {
                pageSize: this.pageSize,
                ct: ''
            }
            let res = await orderApi.orderList(params)
            this.orderList = res.data
            this.loading = false
        },
        // mescroll组件初始化的回调,可获取到mescroll对象
        mescrollInit(mescroll) {
            this.mescroll = mescroll
        },
        // 上拉回调 
        upCallback() {
            let params = {
                pageSize: this.pageSize,
                ct: this.orderList.length === 0 ? "" : this.orderList[this.orderList.length - 1].createAt
            }
            orderApi.orderList(params).then((res) => {
                this.orderList.push(...res.data)
                this.mescroll.endSuccess(this.dataList.length);
            }).catch((err) => {
                this.mescroll.endErr()
            })
        },
        //下拉刷新
        downCallback() {
            let params = {
                pageSize: this.pageSize,
                ct: ''
            }
            orderApi.orderList(params).then((res) => {
                this.orderList = res.data
                this.mescroll.endSuccess(this.dataList.length);
            }).catch((err) => {
                this.mescroll.endErr()
            })
        },
        goBack() {
            history.pushState(null, null, document.URL);
        },
    },
}
</script>
<style lang="scss" scoped>
.order {
    min-height: 100vh;
    overflow: hidden;
}

.orderCard {
    max-height: 139px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    box-sizing: border-box;
    margin: 20px 24px;
    display: flex;
    overflow: hidden;
    position: relative;
}

.orderInfo {
    margin: 16px 0 12px 14px;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    color: #999999;
    overflow: hidden;
}

.orderStatus {
    width: 74px;
    height: 32px;
    background: #1477FD;
    border-radius: 8px 0px 8px 0px;
    position: absolute;
    text-align: center;
    line-height: 32px;
    bottom: 0;
    right: 0;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

/*通过fixed固定mescroll的高度*/
.mescroll {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto;
}

.emptyPic {
    margin-top: 100px;
    text-align: center;
}

.van-loading {
    margin-top: 200px;
    text-align: center;
}

.van-skeleton {
    padding: 0 24px !important;
}

.van-skeleton__content {
    height: 100vh;
    overflow: hidden;
}

.van-skeleton__row,
.van-skeleton__title {
    width: 100% !important;
    height: 128px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
</style>

